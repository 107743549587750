<template>
  <div class="styleguide">
    <b-container>
      <b-row>
        <b-col class="text-center"><h2>Main Colors</h2></b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--dark-royal-blue)">
            <span class="text-white">--dark-royal-blue</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--mango)">
            <span class="text-white">--mango</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: black">
            <span class="text-white">black</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: white">
            <span class="text-black">white</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center"><h2>Secondary Colors</h2></b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--brightyellow)">
            <span class="text-black">--brightyellow</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--lightblue)">
            <span class="text-white">--lightblue</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--medium-green)">
            <span class="text-white">--medium-green</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--coral)">
            <span class="text-white">--coral</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--deep-lavender)">
            <span class="text-white">--deep-lavender</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center"><h2>Badge Colors</h2></b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--goldenrod)">
            <span class="text-white">--goldenrod</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--silver)">
            <span class="text-white">--silver</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--bronze)">
            <span class="text-white">--bronze</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center"><h2>Team Colors</h2></b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--mango)">
            <span class="text-white">--mango</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--lightblue)">
            <span class="text-white">--lightblue</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--medium-green)">
            <span class="text-white">--medium-green</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--coral)">
            <span class="text-white">--coral</span>
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="tile" style="background-color: var(--deep-lavender)">
            <span class="text-white">--deep-lavender</span>
          </div>
        </b-col><b-col class="text-center">
          <div class="tile" style="background-color: var(--very-light-pink)">
            <span class="text-white">--very-light-pink</span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>
<style scoped lang="scss">
.container {
  background-color: #f8f9fa;
  color: black;
  padding: 20px;
}
.tile {
  width: 100%;
  height: 100px;
}
.text-white {
  color: white;
}
.text-black {
  color: black;
}
</style>
